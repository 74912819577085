<template>
    <div class="tw-flex-grow-[1] tw-basis-0 tw-shrink-0 tw-self-center tw-relative tw-m-0 tw-p-0.5 md:tw-flex-grow-[0.5] basket-btn-wrap basket-btn-click tw-text-center">
        <div class="display-table">
            <div class="display-table-cell tw-text-center">
                <div class="basket tw-inline-block tw-align-middle tw-relative tw-overflow-visible tw-group">
                    <a
                        class="hover:tw-no-underline"
                        href="/basket"
                    >
                        <div class="tw-h-7 tw-flex tw-items-center tw-justify-center"><i class="icon-shopping-cart tw-text-white tw-text-lg group-hover:tw-text-chards-gold" /><br></div>
                        <span class="basket-item-count tw-bg-chards-gold tw-font-sans tw-rounded-full tw-text-white tw-block tw-text-[11px] tw-leading-[14px] tw-min-w-[18px] tw-p-0.5 tw-absolute tw--top-2.5 tw--right-2.5">
                            {{ basketCount }}
                        </span>
                        <span class="tw-text-center tw-text-chards-gold tw-font-sans tw-text-[0.4375rem] lg:tw-text-xs group-hover:tw-text-white">
                            Basket
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapStores} from "pinia";
import {useBasketStore} from "@stores/basketStore.js";

export default {
    name: 'HeaderBasket',
    data() {
        return {
            basketCount: 0,
        };
    },
    computed: {
        ...mapStores(useBasketStore),
    },
    watch: {
        'basketStore.itemCount'() {
            this.getBasketCount();
        },
    },
    mounted() {
        this.setBasketCount();
    },
    methods: {
        setBasketCount() {
            this.basketStore.setItemCount();
        },
        getBasketCount() {
            this.basketCount = this.basketStore.itemCount;
        }
    }
};
</script>
